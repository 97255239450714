import ApplicationController from '../../../javascript/controllers/application_controller';

export default class extends ApplicationController {
  static targets = ["results", "loadingNotice"]

  showQueryStarted() {
    if (this.hasResultsTarget) {
      this.resultsTarget.classList.add("hidden")
    }

    this.loadingNoticeTarget.classList.remove("hidden")
  }

}
