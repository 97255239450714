import ApplicationController from '../../../javascript/controllers/application_controller';
import { debounce } from "debounce"

export default class extends ApplicationController {
  static targets = [
    "form",
    "amountAttributeFilledInput",
    "orderTypeInput",
    "submitButton"
  ]

  connect() {
    super.connect()
    this.debouncedStimmy = debounce(this.stimmy, 300)
    this.activateOrderTypeLabel()

    setInterval(() => {
      this.stimmy()
    }, 30000);
  }

  finalizeReflex() {
    this.activateOrderTypeLabel()
  }

  baseAmountUpdated(event) {
    this.setAmountAttributeFilled("base_amount")

    let target = event.currentTarget
    this.replaceQueryParam(target.name, target.value)

    this.onFormChange()
  }

  quoteAmountUpdated(event) {
    this.setAmountAttributeFilled("quote_amount")

    let target = event.currentTarget
    this.replaceQueryParam(target.name, target.value)

    this.onFormChange()
  }

  onFormChange() {
    this.submitButtonTarget.classList.add("-loading")
    this.submitButtonTarget.setAttribute("disabled", "disabled")
    this.debouncedStimmy()
  }

  onCheckOrderType(event) {
    this.selectOrderType(event.currentTarget)
    this.onFormChange();
  }

  selectOrderType(target) {
    this.replaceQueryParam(target.name, target.value)

    let labels = target.parentElement.parentElement.parentElement.
      querySelectorAll("label[for='user_quote_order_type_sell'],label[for='user_quote_order_type_buy']")

    labels.forEach((label) => { label.classList.remove("active") })

    let label = target.parentElement
    label.classList.add("active")
  }

  stimmy() {
    this.stimulate("QuotesReflex#new", { serializeForm: true })
  }

  setAmountAttributeFilled(str) {
    this.amountAttributeFilledInputTarget.value = str
    this.replaceQueryParam("user_quote[amount_attribute_filled]", str)
  }

  activateOrderTypeLabel() {
    this.orderTypeInputTargets.forEach((orderTypeInputTarget) => {
      let label = orderTypeInputTarget.parentElement

      if (orderTypeInputTarget.getAttribute("checked") != undefined) {
        label.classList.add("active")
      } else {
        label.classList.remove("active")
      }
    })
  }

}
