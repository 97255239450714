import { Controller } from "stimulus"
import CableReady from "cable_ready"
import consumer from "../../../../javascript/channels/consumer"

export default class extends Controller {
  static values = { userOrderId: Number }
  static targets = [ "waitMessage" ]

  initialize() {
    this.waitMessages = [
      "Should be done in a few moments",
      "Tolerating some Byzantine faults",
      "Planting some Merkle trees",
      "Hashing our algorithms",
      "Incentivizing honest nodes",
      "Writing tweets about HODLing",
      "Just a couple more seconds",
      "Restarting our cloud miners",
      "Defractionalizing our reserves",
      "Rebalancing our crypto portfolio",
      "Looking for an affordable Beeple NFT",
      "Telling Craig Wright he's not the real Satoshi"
    ]
  }

  connect() {
    this.subscribe()
    this.setupWaitMessageCycler()
  }

  subscribe() {
    consumer.subscriptions.create({
      channel: "UserOrderChannel",
      user_order_id: this.userOrderIdValue
    }, {
      received (data) {
        if (data.cableReady) CableReady.perform(data.operations)
      }
    });
  }

  setupWaitMessageCycler() {
    this.changeWaitMessage()

    setInterval(()=> { this.changeWaitMessage() }, 5000)
  }

  changeWaitMessage() {
    if (!this.hasWaitMessageTarget) { return true }

    var n = Math.floor(Math.random()*this.waitMessages.length)
    var newMsg = this.waitMessages[n]

    this.waitMessageTarget.textContent = `${newMsg}...`
  }
}
