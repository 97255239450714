// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("jquery")

// JS
import 'jquery';
import ahoy from "ahoy.js"
import "chartkick/chart.js"

// CSS
import 'css/tailwind';
import 'css/heat';

// IMG
const images = require.context('../images', true);
const imagePath = (name) => images(name, true);

window.jQuery = $;
window.$ = $;

import "controllers"


if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js',{ scope: "/" })
      .then(function(registration) {
        console.log('[ServiceWorker Client]', 'registration successful with scope: ', registration.scope);
        caches.open('documents').then((cache) => cache.add('/offline.html'))
      }, function(err){
        console.log('[ServiceWorker Client]', 'registration failed: ', err)
      });
  });
}