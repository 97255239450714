import ApplicationController from '../../../../javascript/controllers/application_controller';

export default class extends ApplicationController {
  static targets = ["suggester", "textarea"]

  connect() {
    super.connect()
    import("@github/text-expander-element")
  }

  change(event) {
    const {key, provide, text} = event.detail
    const _this = this

    provide(
      Promise.resolve(
        _this.stimulate(
          "TextExpander#get_message_suggestions",
          text,
          _this.textareaTarget.id
        )
      ).then(() => {
        const textarea = _this.textareaTarget
        const startPos = textarea.selectionStart
        const endPos = textarea.selectionEnd

        const suggestions = _this.suggesterTarget.cloneNode(true)
        suggestions.style = "left: " + startPos + "px; top: " + endPos + "px;"
        suggestions.hidden = false

        return {
          matched: suggestions.childElementCount > 0,
          fragment: suggestions
        }
      })
    )
  }

  value(event) {
    const {key, item}  = event.detail
    if (key === '#') {
      event.detail.value = item.getAttribute('data-value')
    }
  }

  buildPreview() {
    this.stimulate(
      "TextExpanderPreview#build_message_preview",
      this.textareaTarget.value,
      this.textareaTarget.id
    )
  }

}
