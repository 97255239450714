import ApplicationController from "./application_controller"
import jsQR from "jsqr"

const defaultHeight = 240
const defaultWidth = 320

// NOTE: this is almost an exact copy of the qr_code_scanner_controller
// in the js-app folder. Can delete the other when we have migrated away from
// that completely

/*
 * Sample Usage:
 * =============
 *
 * div data-controller='qr-code-scanner'
 *   canvas data-qr-code-scanner-target='videoContainer' hidden='hidden'
 *   input type='button' value='Scan' data-qr-code-scanner-target='start' data-action='click->qr-code-scanner#scan'
 *   input type='button' value='Stop' data-qr-code-scanner-target='stop' data-action='click->qr-code-scanner#stop'
 *   input type='text' data-qr-code-scanner-target='output'
 *
 */

export default class extends ApplicationController {

  static targets = ["videoContainer", "output", "start", "stop", "height", "width"]

  static video
  static videoHeight
  static videoWidth
  static stream
  static canvasElement
  static canvas

  connect() {
    super.connect()
    console.log("- connected to qr-code-scanner -")

    this.hooks = []
    this.stopTarget.hidden = true
    this._setVideoHeight()
    this._setVideoWidth()
  }

  disconnect() {
    this._closeCamera()
  }

  scan() {
    this.video = document.createElement("video")
    this.canvasElement = this.videoContainerTarget
    this.canvas = this.canvasElement.getContext("2d")

    const context = this

    navigator.mediaDevices.getUserMedia({ video: { facingMode: "environment" } }).then(function(stream) {
      context.stream = stream
      context.video.srcObject = stream
      context.video.setAttribute("playsinline", true) // required to tell iOS safari we don't want fullscreen
      context.video.play()

      requestAnimationFrame(context._tick.bind(context))
    });

    this.startTarget.hidden = true
    this.stopTarget.hidden = false
  }

  stop() {
    this._closeCamera()
  }

  registerHook(fn) {
    this.hooks.push(fn)
  }

  _tick() {
    if (this.video.readyState === this.video.HAVE_ENOUGH_DATA) {
      this.canvasElement.hidden = false
      this.canvasElement.height = this.videoHeight
      this.canvasElement.width = this.videoWidth
      this.canvas.drawImage(this.video, 0, 0, this.canvasElement.width, this.canvasElement.height)
      const imageData = this.canvas.getImageData(0, 0, this.canvasElement.width, this.canvasElement.height)
      const code = jsQR(imageData.data, imageData.width, imageData.height, {
        inversionAttempts: "dontInvert",
      })

      if (code) {
        this._drawLine(code.location.topLeftCorner, code.location.topRightCorner, "#FF3B58")
        this._drawLine(code.location.topRightCorner, code.location.bottomRightCorner, "#FF3B58")
        this._drawLine(code.location.bottomRightCorner, code.location.bottomLeftCorner, "#FF3B58")
        this._drawLine(code.location.bottomLeftCorner, code.location.topLeftCorner, "#FF3B58")

        console.log(`Found -- ${code.data}`)

        if (this.hasOutputTarget) {
          this.outputTarget.value = code.data
          this.outputTarget.dispatchEvent(new Event("input"))
        }

        // Allow others to set hooks so they can do something with the data
        // when it is scanned
        // This hasn't been completely tested, but it would be nice to turn
        // this into a view component that can be called from anywhere in the
        // app
        this.hooks.forEach((hook) => {
          hook(code.data)
        })

        this._closeCamera()
      }
    }
    requestAnimationFrame(this._tick.bind(this))
  }

  _drawLine(begin, end, color) {
    this.canvas.beginPath()
    this.canvas.moveTo(begin.x, begin.y)
    this.canvas.lineTo(end.x, end.y)
    this.canvas.lineWidth = 4
    this.canvas.strokeStyle = color
    this.canvas.stroke()
  }

  _closeCamera() {
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop())
    }

    // Do not blow up when replacing part of page
    // that contains the QR code scanner controller
    if (this.canvasElement) {
      this.canvasElement.hidden = true
    }
    this.startTarget.hidden = false
    this.stopTarget.hidden = true
  }

  _setVideoHeight() {
    if (this.hasHeightTarget) {
      this.videoHeight = this.heightTarget
    } else {
      this.videoHeight = defaultHeight
    }
  }

  _setVideoWidth() {
    if (this.hasWidthTarget) {
      this.videoWidth = this.widthTarget
    } else {
      this.videoWidth = defaultWidth
    }
  }

}
