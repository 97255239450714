import Dropzone from "dropzone";
import { Controller } from "stimulus";
import {
  getMetaValue,
  findElement,
  removeElement,
} from "helpers";

export default class extends Controller {
  static targets = ["input"];

  connect() {
    this.dropZone = createDropZone(this);
    this.hideFileInput();
    this.bindEvents();
    Dropzone.autoDiscover = false; // necessary quirk for Dropzone error in console
  }

  // Private
  hideFileInput() {
    this.inputTarget.disabled = true;
    this.inputTarget.style.display = "none";
  }

  bindEvents() {
    this.dropZone.on("removedfile", file => {
      file.controller && removeElement(file.controller.hiddenInput);
    });

    this.dropZone.on("canceled", file => {
      file.controller && file.controller.xhr.abort();
    });

    this.dropZone.on("complete", file => {
      if (file.status == 'success') {
        findElement(
          this.element.getAttribute('data-dropzone-list')
        ).innerHTML = file.xhr.response;

        this.dropZone.removeFile(file);
      }
    })
  }

  get headers() {
    return { "X-CSRF-Token": getMetaValue("csrf-token") };
  }

  get url() {
    return this.inputTarget.getAttribute("data-url");
  }

  get maxFiles() {
    return this.inputTarget.getAttribute("data-max-files");
  }

  get maxFileSize() {
    return this.data.get("maxFileSize");
  }

  get acceptedFiles() {
    return this.inputTarget.getAttribute("data-acceptedFiles");
  }

  get addRemoveLinks() {
    return this.data.get("addRemoveLinks") || true;
  }

  get dropzoneList() {
    return this.inputTarget.getAttribute("data-dropzone-list") || ".dropzone-list";
  }
}

function createDropZone(controller) {
  return new Dropzone(controller.element, {
    url: controller.url,
    headers: controller.headers,
    maxFiles: controller.maxFiles,
    maxFilesize: controller.maxFileSize,
    acceptedFiles: controller.acceptedFiles,
    addRemoveLinks: controller.addRemoveLinks,
  });
}
