import ApplicationController from '../../../javascript/controllers/application_controller';
import { debounce } from "debounce"

export default class extends ApplicationController {
  static targets = ["queryInput"]

  connect() {
    super.connect()
    this.debouncedStimmy = debounce(this.stimmy, 500)
  }

  onQueryChange(event) {
    this.replaceQueryParam("page", null)
    this.replaceQueryParam("search[query]", event.currentTarget.value)
    this.debouncedStimmy()

    this.dispatch("assetsSearchStarted")
  }

  stimmy() {
    this.stimulate("AssetsSearchReflex#new", { serializeForm: true })
  }
}
