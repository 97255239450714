import ApplicationController from '../../../javascript/controllers/application_controller';
import { sanitize_address } from '../../../javascript/js-app/src/sanitize_crypto_address';
import { debounce } from "debounce"

export default class extends ApplicationController {
  static targets = [ "form", "channelCode", "minAmount", "maxAmount", "amountInput" ]

  connect() {
    super.connect()
    this.watchForm()
    this.debouncedStimmy = debounce(this.stimmy, 500)
    this.debouncedAddressCheck = debounce(this.address_check, 250)
  }

  afterReflex(element) {
    this.connectQrCodeScanner()
  }

  watchForm() {
    this.formTarget.addEventListener("change", () => { this.onFormChange() } )
  }

  onFormChange() {
    this.debouncedStimmy()
  }

  setMinAmount(event) {
    this.amountInputTarget.value = this.minAmountTarget.value;
    event.preventDefault();
  }

  setMaxAmount(event) {
    this.amountInputTarget.value = this.maxAmountTarget.value;
    event.preventDefault();
  }

  onInputChange(event) {
    //added this check so only the address field triggers the check
    if(event.target.id == "user_withdrawal_channel_code") {
      this.debouncedAddressCheck()
    }
    this.debouncedStimmy()
  }

  address_check() {
    var address_input = document.getElementById("user_withdrawal_channel_code")
    var targetValue = address_input.value

    //we sanitize the address using the sanitize_crypto_address js
    address_input.value = sanitize_address(targetValue)
  }

  stimmy() {
    this.stimulate("UserWithdrawalsReflex#new", { serializeForm: true })
  }

  connectQrCodeScanner() {
    if (this.qrCodeScannerController) {
      this.qrCodeScannerController.connect()
    }
  }

  get qrCodeScannerController() {
    let el = this.element.querySelector('[data-controller="qr-code-scanner"]');

    if (el == null) {
      // Sometimes we don't render the QR scanner when the withdrawal target
      // does not have an account number / address
      return null
    } else {
    return el["qr-code-scanner"]
    }
  }
}
