import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = [
    'menu',
    'button',
    'joke',
    'body',
    'content',
    'closeIcon',
    'menuIcon'
  ]
  static values = { open: Boolean }

  connect() {
    this.toggleClass = "hidden"
    this.visibleClass = "opacity-100 scale-100"
    this.invisibleClass = "opacity-0 scale-95"
    this.activeClass = "bg-bloom-gold"
    this.enteringClass = "ease-out duration-100"
    this.leavingClass = "ease-in duration-75"

    if (this.hasButtonTarget) {
      this.buttonTarget.addEventListener("keydown", this._onMenuButtonKeydown)
    }

    this.element.setAttribute("aria-haspopup", "true")
  }

  disconnect() {
    if (this.hasButtonTarget) {
      this.buttonTarget.removeEventListener("keydown", this._onMenuButtonKeydown)
    }
  }


  toggle() {
    this.openValue = !this.openValue
  }

  openValueChanged() {
    if (this.openValue) {
      this._show()
    } else {
      this._hide()
    }
  }

  _show(cb) {
    setTimeout(
      (() => {
        this.menuTarget.classList.remove(this.toggleClass)
        this.element.setAttribute("aria-expanded", "true")

        this.closeIconTarget.classList.remove("hidden")
        this.menuIconTarget.classList.add("hidden")

        this._enteringClassList[0].forEach(
          (klass => {
            this.menuTarget.classList.add(klass)
          }).bind(this),
        )

        this._activeClassList[0].forEach(klass => {
          this.activeTargt.classList.add(klass)
        })
        this._invisibleClassList[0].forEach(klass => this.menuTarget.classList.remove(klass))
        this._visibleClassList[0].forEach(klass => {
          this.menuTarget.classList.add(klass)
        })
        setTimeout(
          (() => {
            this._enteringClassList[0].forEach(klass => this.menuTarget.classList.remove(klass))
          }).bind(this),
          this.enterTimeout[0],
        )

        if (typeof cb == 'function') cb()
      }).bind(this),
    )
  }

  _hide(cb) {
    setTimeout(
      (() => {
        this.element.setAttribute("aria-expanded", "false")
        this._invisibleClassList[0].forEach(klass => this.menuTarget.classList.add(klass))
        this._visibleClassList[0].forEach(klass => this.menuTarget.classList.remove(klass))
        this._activeClassList[0].forEach(klass => this.activeTargt.classList.remove(klass))
        this._leavingClassList[0].forEach(klass => this.menuTarget.classList.add(klass))
        setTimeout(
          (() => {
            this._leavingClassList[0].forEach(klass => this.menuTarget.classList.remove(klass))
            if (typeof cb == 'function') cb()

            this.menuTarget.classList.add(this.toggleClass)

            this.closeIconTarget.classList.add("hidden")
            this.menuIconTarget.classList.remove("hidden")

          }).bind(this),
          this.leaveTimeout[0],
        )
      }).bind(this),
    )
  }

  _onMenuButtonKeydown = event => {
    switch (event.keyCode) {
      case 13: // enter
      case 32: // space
        event.preventDefault()
        this.toggle()
    }
  }

  show(){
     this.openValue = true;
  }

  hide(event) {
    if (this.element.contains(event.target) === false && this.openValue) {
      this.openValue = false
    }
  }

  get activeTargt() {
    return document.querySelector("#mobile-menu")
  }

  get _activeClassList() {
    return !this.activeClass
      ? [[], []]
      : this.activeClass.split(',').map(classList => classList.split(' '))
  }

  get _visibleClassList() {
    return !this.visibleClass
      ? [[], []]
      : this.visibleClass.split(',').map(classList => classList.split(' '))
  }

  get _invisibleClassList() {
    return !this.invisibleClass
      ? [[], []]
      : this.invisibleClass.split(',').map(classList => classList.split(' '))
  }

  get _enteringClassList() {
    return !this.enteringClass
      ? [[], []]
      : this.enteringClass.split(',').map(classList => classList.split(' '))
  }

  get _leavingClassList() {
    return !this.leavingClass
      ? [[], []]
      : this.leavingClass.split(',').map(classList => classList.split(' '))
  }

  get enterTimeout() {
    let timeout = "100" || '0,0'
    return timeout.split(',').map(t => parseInt(t))
  }

  get leaveTimeout() {
    let timeout = "75" || '0,0'
    return timeout.split(',').map(t => parseInt(t))
  }

  joke() {
    this.toggle()
    var CLOSETIMEOUT=0;

    fetch("/jokes.json")
      .then(response => response.json())
      .then(json => {
        var _bodyTarget = this.bodyTarget
        var _contentTarget = this.contentTarget
        var _jokeTarget = this.jokeTarget

        _bodyTarget.classList.remove("hidden");
        _bodyTarget.classList.remove("opacity-0");
        _bodyTarget.animate({ opacity: 1 }, 500);

        clearTimeout(CLOSETIMEOUT);

        _contentTarget.innerHTML = json.joke
        _jokeTarget.innerHTML = "🤣"

        setTimeout(function(){
          _jokeTarget.innerHTML = "🙂"
        }, 3500);

        CLOSETIMEOUT = setTimeout(function(){
          _bodyTarget.animate({ opacity: 0 }, 500);
          _bodyTarget.classList.add("opacity-0");
          _bodyTarget.classList.add("hidden");
        }, 10000);
      })

  }

}
