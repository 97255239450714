import ApplicationController from '../../../javascript/controllers/application_controller';

export default class extends ApplicationController {
  connect() {
    super.connect();
  }

  selectNetwork(event) {
    this.stimulate("UserDepositsReflex#address", event.target.value)
  }
}
