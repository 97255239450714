import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["navSelect"];

  connect() {
    var navSelectTarget = this.navSelectTarget;

    navSelectTarget.addEventListener('change', (event) => {
      window.location.href = navSelectTarget.value;
    });
  }
}
