import intlTelInput from 'intl-tel-input';
import { Controller } from "stimulus";

const intlTelInputUtils = require('intl-tel-input/build/js/utils.js');

export default class extends Controller {
  static targets = ["input"];

  connect() {
    intlTelInput(this.inputTarget, {
      initialCountry: "ph",
      preferredCountries: ["ph"],
      utilsScript: intlTelInputUtils,
      hiddenInput: "full_mobile",
    });
  }

}
