import ApplicationController from '../../../javascript/controllers/application_controller';

export default class extends ApplicationController {
  static targets = ["form"]
  static values = {
    reflexName: String
  }

  submit(event) {
    event.preventDefault()

    this.stimulate(`${this.reflexNameValue}#submit`);
  }

}
