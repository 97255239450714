import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "inputDiv", "checkBox"];

  connect() {
    if (!this.checkBoxTarget.checked) {
      this.inputDivTarget.classList.toggle("hidden");
      this.inputTargets.forEach(function(item, index, array) {
        item.toggleAttribute("required");
      });
    }
  }

  open() {
    this.inputDivTarget.classList.toggle("hidden");
    this.inputTargets.forEach(function(item, index, array) {
      item.toggleAttribute("required");
    });
  }

}
