import ApplicationController from '../../../javascript/controllers/application_controller';
import { debounce } from "debounce"

export default class extends ApplicationController {
  static targets = [ "form", "amountInput", "alertTypeRadio", "currentPrice", "threeFromPrice", "threeToPrice" ]

  connect() {
    super.connect()
    this.watchForm()
    this.debouncedStimmy = debounce(this.stimmy, 400)
  }

  watchForm() {
    this.formTarget.addEventListener("change", () => { this.onFormChange() } )
  }

  onFormChange() {
    this.debouncedStimmy()
  }

  onInputChange() {
    this.debouncedStimmy()
  }

  setPrice(event) {
    event.preventDefault();
    this.amountInputTarget.value = event.target.value;
    this.debouncedStimmy()
  }

  stimmy() {
    this.stimulate("PriceAlertsReflex#new", { serializeForm: true })
  }
}