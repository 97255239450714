import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ["countdown", "number", "circle"]

  initialize() {
    this.countdown_secs = Number.parseInt(this.element.dataset.time) - Math.floor(Date.now() / 1000);
    this.circle_timer_circumference = 113
    this.circle_step = 0
    this.circle_change_per_sec = this.circle_timer_circumference/this.countdown_secs;
    this.time_increments = 1000;
  }

  connect() {
    this.timer()
  }

  toClock(int){
    if (int>0) {
      var minutes = Math.floor(int/60);
      var seconds = int%60;
      if (minutes<10) { minutes = "0" + minutes; }
      if (seconds<10) { seconds = "0" + seconds; }
    } else {
      var minutes = "00",
          seconds = "00";
    }

    return minutes+":"+seconds;
  }

  timer() {
    var obj = this;
    this.interval = setInterval(function() {
      --obj.countdown_secs;

      if (obj.circle_timer_circumference>obj.circle_step) {
        obj.circle_step += obj.circle_change_per_sec;
        obj.circleTarget.style.strokeDashoffset = obj.circle_step+"px"
      } else {
        obj.circleTarget.style.strokeDashoffset = obj.circle_timer_circumference+"px"
      }

      if (obj.countdown_secs==0) {
        clearInterval(obj.interval);
        obj.interval=0;
        // TODO: do not let component control the page, but rather allow
        // something else in the page to know when the timer is up
        location.reload();
      } else if (obj.countdown_secs<=5) {
        obj.countdownTarget.classList.add("animate-blinker")
      } else if (obj.countdown_secs<=15) {
        obj.numberTarget.classList.add("text-red-500")
        obj.circleTarget.classList.add("text-red-500")
      }
      obj.numberTarget.innerHTML = obj.toClock(obj.countdown_secs)
    }, this.time_increments);
  }

}
