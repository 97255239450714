// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import StimulusReflex from 'stimulus_reflex'
import consumer from '../channels/consumer'
import controller from '../controllers/application_controller'

const application = Application.start()

const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

// ViewComponents
const componentContext = require.context("../../components/", true, /(.*)\/.+\.js$/);
application.load(definitionsFromContext(componentContext));

StimulusReflex.initialize(application, { controller, isolate: false })
StimulusReflex.debug = process.env.RAILS_ENV === 'development'
application.consumer = consumer

function importAll(r) {
  r.keys().forEach(r);
}

importAll(require.context("../../components/", true, /\.scss$/));
