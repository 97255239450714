import ApplicationController from '../../../javascript/controllers/application_controller';
import debounce from "lodash/debounce"

export default class extends ApplicationController {
  static targets = ["chartdiv"]

  connect() {
    super.connect();
    this.generate = debounce(this.generate.bind(this), 3000);
  }

  generate() {
    this.stimulate(
      "PriceChart#generate",
      this.chartdivTarget.id,
      this.chartdivTarget.dataset.cmcId,
      this.chartdivTarget.dataset.numDays,
    )
  }

  afterGenerate(element) {
    eval($(element).find("script")[0].text)
  }

}
