import ApplicationController from '../../../javascript/controllers/application_controller';

export default class extends ApplicationController {
  static targets = ["plusIcon", "minusIcon", "answer"]

  toggleAnswer(event) {
    this.answerTarget.classList.toggle("hidden");
    this.plusIconTarget.classList.toggle("hidden");
    this.minusIconTarget.classList.toggle("hidden");
  }
}
