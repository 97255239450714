//we define our known formats
const address_format = ["bitcoin:", "ethereum:"];

export function sanitize_address(crypto_address) {
  var sanitized_address = crypto_address;

  //we loop through the array
  //since it's just two for now, we can loop through the list, but if the list gets bigger
  //we need to change the approach
  for (var format of address_format) {
    if(crypto_address.includes(format)) {
      sanitized_address = crypto_address.replace(format, "")
      break;
    }
  }

  return sanitized_address;
}