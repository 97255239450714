import ApplicationController from '../../../javascript/controllers/application_controller';
import { debounce } from "debounce"

export default class extends ApplicationController {
  static targets = ["closeIcon", "closingIcon"]

  close(event) {
    event.preventDefault()

    this.closeIconTarget.classList.add("hidden")
    this.closingIconTarget.classList.remove("hidden")

    this.stimulate("AnnouncementDismissalReflex#create", {serializeForm: true})
  }
}

